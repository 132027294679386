
import ApplicationController from './application_controller'

import Rails from "@rails/ujs";

export default class extends ApplicationController {

  static targets = ["schedule_date", "classes"]

  listClasses(startDate, targetURL) {
    Rails.ajax({
      type: "GET",
      url: this._classesUrl(targetURL, startDate),
      success: (data) => {
        console.log(data)
        this._refreshClassValues(data)
      },
        error: (data) => {
            console.log(data)
        }
    })
  }

  updateClassTimes(event){
    var startDate = event.target.value;
    var classesURL = $(event.target).data('classes-url')
    this.listClasses(startDate, classesURL)
  }

  _refreshClassValues(data) {
    $('#leadClassContainer').show()
    var classes = $(this.classesTarget)
    classes.empty()

    $.each(data, function(index, tuple) {
        classes.append("<option value='"+ tuple[0] + "'>" + tuple[1] + "</option>")
    });
  }

  _classesUrl(targetURL, startDate) {
    return targetURL + "?schedule_date=" + startDate;
  }

}

